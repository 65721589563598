










































































import { VueModalPopUpViewModel } from '@/entities/VueModalPopUpViewModel';
import { Component, Prop, Vue } from 'vue-property-decorator';
import VueModalPopUpButtonTextEnum from '@/enums/VueModalPopUpButtonTextEnum';
@Component
export default class VueModalPopUp extends Vue {
  @Prop() private ShowModalPopup?: boolean;
  @Prop() private ModalPopUp?: VueModalPopUpViewModel;
  @Prop() private IsContentLeftAligned?: boolean;
  private deleteBtn = VueModalPopUpButtonTextEnum[VueModalPopUpButtonTextEnum.Delete].toString();
  private Cancel(): void {
    // close modal pop up and emit to parent component
    this.$emit('close');
  }
  private Save() {
    // save modal pop up and emit to parent component
    this.$emit('save', this.ModalPopUp);
  }
  private ShowHideSaveButton() {
    // show and hide btn
    return (this.ModalPopUp!.Button2Text !== '' && this.ModalPopUp!.Button2Text !== undefined);
  }
}
