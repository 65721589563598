





import {Vue, Component} from 'vue-property-decorator';
import Groups from '@/components/Participants/Groups/Groups.vue';
@Component({
  components: {
    Groups,
  },
})
export default class Participants extends Vue {
  private mounted() {
    this.$root.$emit('set-active-component', 'participant');
  }
}
