












































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Datepicker from '@/components/Common/Datepicker.vue';
import { BrowserSettingsInfo } from '@/entities/BrowserSettingsInfo';
import { StoreHelper } from '@/store/StoreHelper';
import GroupSelectionResponseOptions from './GroupSelectionResponseOptions.vue';
import GroupSelectionDetails from '@/entities/Participants/GroupSelectionDetails';
import WarningMessageBar from '@/components/Common/WarningMessageBar.vue';
import { WarningMessageBarViewModel } from '@/entities/WarningMessageBarViewModel';
import { ParticipantGroups } from '@/entities/ParticipantGroups';
import GroupSelectionOptionViewModel from '@/entities/Participants/GroupSelectionOptionViewModel';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import CreateGroup from '@/components/Common/CreateGroup.vue';
import { minLength, required } from 'vuelidate/lib/validators';
import VueModalPopUp from '@/components/Common/VueModalPopUp.vue';
import { VueModalPopUpViewModel } from '@/entities/VueModalPopUpViewModel';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { ParticipantsGroupSelectionSignalRModel } from '@/signalRRequest/Participants/ParticipantsGroupSelectionSignalRModel';
import moment from 'moment-timezone';
import Common from '@/helper/Common';
@Component({
  validations: {
    localSelectedItem: {
      Title: {
        required,
        minLength: minLength(1),
      },
    },
  },
  methods: {
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },
  },
  components: {
    Datepicker,
    GroupSelectionResponseOptions,
    WarningMessageBar,
    ValidationSummary,
    VueModalPopUp,
    CreateGroup,
  },
})
export default class GroupSelectionEditItemPane extends Vue {
  @Prop() SelectedItem!: GroupSelectionDetails;
  @Prop() Groups!: ParticipantGroups;
  private storeHelper: StoreHelper = new StoreHelper(this.$store);
  private minDateTime = new Date().toISOString();
  private localSelectedItem: GroupSelectionDetails = GroupSelectionDetails.createEmpty(); // local group selection question selected item
  private informationMessage: WarningMessageBarViewModel = WarningMessageBarViewModel.createEmpty();
  private isIEbrowser = false;
  private validationErrorFields: string[] = [];
  private isValidationSummaryVisible = false;
  private unsavedModalPopUp: VueModalPopUpViewModel = VueModalPopUpViewModel.createEmpty();
  private showVueModalPopUp = false;
  private showCreateNewGroup = false;
  private minGroupSelectionSeatQuantity = 1;
  private maxGroupSelectionSeatQuantity = 100000;
  private createNewGroupIndex = 0;
  private maxGroupSelectionTitleLength = 160;
  private deepmerge = require('deepmerge'); // for deep copy
  private timeZone = this.storeHelper.GetActiveEventIanaTimeZoneFromStore();
  private alreadySelectedDate = new Date();
  private mounted() {
    this.localSelectedItem = JSON.parse(JSON.stringify(this.SelectedItem));
    this.informationMessage = new WarningMessageBarViewModel(
      '',
      this.$t('Participants.GroupSelection.ReadOnlyMode').toString(),
      this.$t('InformationAlert').toString(),
    );
    if(this.localSelectedItem.HasDeadline) {
      // Converting AvailableTillUTC according to event timezone
      this.localSelectedItem.AvailableTillUTC = this.localSelectedItem.AvailableTillUTC === null ? moment(new Date()).tz(this.timeZone).toDate() :  moment(this.localSelectedItem.AvailableTillUTC).tz(this.timeZone).toDate();
    }
    // Setting minDateTime according to the event time zone
    this.minDateTime = new Date(this.ConvertDateToUtc(this.GetDateString())).toISOString();
    if(this.localSelectedItem.AvailableTillUTC !== null) {
      this.alreadySelectedDate = this.localSelectedItem.AvailableTillUTC;
    }
    if (navigator.appName === 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/))) {
      this.isIEbrowser = true;
    }
    this.SetModelPopUpMessage();
    this.$root.$on('display-group-creation', (val: boolean, index: number)=> {
      this.showCreateNewGroup = val;
      this.createNewGroupIndex = index;
    });
    this.$root.$on(('reset-group-selection'), ()=> {
      this.localSelectedItem = JSON.parse(JSON.stringify(this.SelectedItem));
    });
    this.$root.$on(
      'group-selection-question-updated',
      (groupSelectionItem: GroupSelectionDetails) => {
        this.UpdateLocalSelectedItem(self, groupSelectionItem);
      },
    );
  }
  // Update local group selection question selected item
  private UpdateLocalSelectedItem(self: any, val: GroupSelectionDetails) {
    self.$v.$reset();
    self.localSelectedItem = this.deepmerge({}, val);
  }
  // Get group selection question deadline(i.e., AvailableTillUTC date)
  private GetGroupSelectionQuestionDeadline() {
    let dateString = '';
    if(this.localSelectedItem.Id > 0 && this.localSelectedItem.AvailableTillUTC !== null) {
      if(typeof(this.localSelectedItem.AvailableTillUTC) === 'string') {
        return dateString = new Date(this.localSelectedItem.AvailableTillUTC).toUTCString();
      } else {
        const availTill = this.localSelectedItem.AvailableTillUTC;
        if (this.timeZone !== '' && this.timeZone !== null && !this.isIEbrowser) {
          dateString = new Date(availTill).toLocaleString('en-Us', {timeZone: this.timeZone});
        } else {
          dateString = new Date().toLocaleString();
        }
      }
    } else {
      // check if timezone is existing in the event
      dateString = this.GetDateString();
    }
    return this.ConvertDateToUtc(dateString);
  }
  private ConvertDateToUtc(dateString: string) {
    const deadlineDate = new Date(dateString);
    const date: Date = new Date(
      Date.UTC(
        deadlineDate.getUTCFullYear(),
        deadlineDate.getMonth(),
        deadlineDate.getDate(),
        deadlineDate.getHours(),
        deadlineDate.getMinutes(),
      ),
    );
    return date;
  }
  private GetDateString() {
    let dateString = '';
    if (this.timeZone !== '' && this.timeZone !== null && !this.isIEbrowser) {
      dateString = new Date().toLocaleString('en-Us', {timeZone: this.timeZone});
    } else {
      dateString = new Date().toLocaleString();
    }
    return dateString;
  }
  // Check browser time format
  private CheckTimeFormat(): boolean {
    const browsersettings: BrowserSettingsInfo = this.storeHelper.GetBrowserSettingsFromStore();
    return browsersettings.isFullDayTimeFormat;
  }
  // Setting AvailableTillUTC date to null when question doesn't have deadline
  private DoesGroupSelectionQuestionHasDeadline() {
    const hasDeadline = (this.$refs.chkHasDeadline as HTMLInputElement).checked;
    if(!hasDeadline) {
      this.localSelectedItem.AvailableTillUTC = null;
    } else {
      this.GetGroupSelectionQuestionDeadline();
    }
  }
  private SetAvailableTillDate(date: Date) {
    // set value
    this.localSelectedItem.AvailableTillUTC = date;
  }
  private SetAvailableTillDateUTC(date: Date) {
    this.alreadySelectedDate = date;
  }
  // Close edit pane
  private Cancel() {
    this.$emit('group-selection-cancel-operation');
  }
  // Setting response option data for particular group selection question
  private SetGroupSelectionResponseOptionsData(data: GroupSelectionOptionViewModel[]) {
    this.localSelectedItem.GroupSelectionOptions = data;
  }
  // Delete button clicked
  private DeleteGroupSelectionItem() {
    this.showVueModalPopUp = true;
  }
  // Validate all fields of particular group selection field
  private ValidateData() {
    this.validationErrorFields = [];
    if(this.localSelectedItem.Title === '') {
      this.validationErrorFields.push(this.$t('Title').toString());
    }
    // Validating date if the group selection question has deadline
    if (this.localSelectedItem.HasDeadline) {
      let min = '';
      if(typeof(this.alreadySelectedDate) !== 'string') {
        const deadline = new Date(this.alreadySelectedDate);
        min = new Date(Date.UTC(
          deadline.getUTCFullYear(),
          deadline.getMonth(),
          deadline.getDate(),
          deadline.getHours(),
          deadline.getMinutes(),
        )).toISOString();
      } else {
        min = this.alreadySelectedDate;
      }
      if(this.minDateTime >= min) {
        this.validationErrorFields.push(this.$t('Participants.GroupSelection.DeadlineError').toString());
      }
    }
    this.ValidateResponseOptions(this.localSelectedItem.GroupSelectionOptions);
  }
  // Validate response options of new/created group selection quesion
  private ValidateResponseOptions(responseOptions: GroupSelectionOptionViewModel[]) {
    let isOptionTitleEmpty = false;
    let isOptionGroupAssigned = true;
    let isSeatLimitWithinRange = true;
    responseOptions.forEach((item)=> {
      if(item.ResponseOptionTitle.trim() === '') {
        isOptionTitleEmpty = true;
      }
      if(item.AssignedGroup.Name === '') {
        isOptionGroupAssigned = false;
      }
      if((item.LimitSeatQuantity < this.minGroupSelectionSeatQuantity || item.LimitSeatQuantity > this.maxGroupSelectionSeatQuantity || isNaN(item.LimitSeatQuantity)) && item.IsLimitSeat) {
        isSeatLimitWithinRange = false;
      }
    });
    // Checking whether any option title is empty
    if(isOptionTitleEmpty) {
      this.validationErrorFields.push(this.$t('SessionQuestions.SelectedOptions').toString());
    }
    // Checking whether any option doesn't have a group assigned
    if(!isOptionGroupAssigned) {
      this.validationErrorFields.push(this.$t('Groups').toString());
    }
    // Checking whether any option having limited seats have value out of range
    if(!isSeatLimitWithinRange) {
      this.validationErrorFields.push(this.$t('Participants.GroupSelection.SeatsLimit',{min: this.minGroupSelectionSeatQuantity, max: this.maxGroupSelectionSeatQuantity}).toString());
    }
  }
  private SendNotificationRequest(isDelete: boolean): SignalRModelRequest {
    setTimeout(() => {
      this.$root.$emit('show-notification-popup', true);
      this.$root.$emit('show-notification-loading', true);
    }, 100);
    const signalR: ParticipantsGroupSelectionSignalRModel = new ParticipantsGroupSelectionSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    let signalObject: SignalRModelRequest = new SignalRModelRequest();
    if (isDelete) {
      signalObject = signalR.SingleDeleteGroupSelectionQuestionSignalRModelRequest;
      const arr: number[] = [];
      arr.push(this.localSelectedItem.Id);
      signalObject.SelectedIds = arr;
      signalObject.Description = this.$t(signalObject.SubmitMessage, {
        selectedCount: arr.length, // Just single item delete
        user: signalR.GetUserName(signalObject, this.$t('UserName.You').toString()),
      }).toString();
    } else {
      if (this.localSelectedItem.Id === 0) {
        signalObject = signalR.SingleCreateGroupSelectionQuestionSignalRModelRequest;
      } else {
        signalObject = signalR.SingleUpdateGroupSelectionQuestionSignalRModelRequest;
        const arr: number[] = [];
        arr.push(this.localSelectedItem.Id);
        signalObject.SelectedIds = arr;
      }
      signalObject.Description = this.$t(signalObject.SubmitMessage, {
        user: signalR.GetUserName(signalObject, this.$t('UserName.You').toString()),
      }).toString();
    }
    const reqInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();
    signalObject.ApplicationId = Number(reqInfo.ApplicationId);
    signalObject.EventId = Number(reqInfo.ApplicationInstanceId);
    signalObject.JSONData = JSON.stringify({selectedName: this.localSelectedItem.Title});
    signalR.DisableGroupSelectionQuestionsAndAddToNotificationPopup(signalObject);
    return signalObject;
  }
  private SendNotificationRequestToQueue(
    signalObject: SignalRModelRequest,
    groupSelectionQuestion: GroupSelectionDetails,
  ) {
    const signalR: ParticipantsGroupSelectionSignalRModel = new ParticipantsGroupSelectionSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    // Case of Single Create as we get the id once response come from DB
    if (signalObject.SelectedIds.length === 0) {
      const arr: number[] = [];
      arr.push(groupSelectionQuestion.Id);
      signalObject.SelectedIds = arr;
    }
    signalObject.JSONData = signalR.GetGroupSelectionQuestionItemToSendToSignalR(groupSelectionQuestion);
    signalR.PerformGroupSelectionSingleOperation(signalObject);
  }
  // Save button clicked
  private Save() {
    this.$v.$touch();
    this.minDateTime = new Date(this.ConvertDateToUtc(this.GetDateString())).toISOString();
    this.ValidateData();
    this.$root.$emit('save-group-selection-question-clicked');
    if(this.validationErrorFields.length !== 0) {
      this.isValidationSummaryVisible = true;
      this.$root.$emit('error-in-options');
    } else {
      this.isValidationSummaryVisible = false;
      const signalObject = this.SendNotificationRequest(false);
      if(this.localSelectedItem.HasDeadline) {
        if(typeof(this.alreadySelectedDate) !== 'string') {
          this.localSelectedItem.AvailableTillUTC = new Date(Date.UTC(
            this.alreadySelectedDate.getUTCFullYear(),
            this.alreadySelectedDate.getMonth(),
            this.alreadySelectedDate.getDate(),
            this.alreadySelectedDate.getHours(),
            this.alreadySelectedDate.getMinutes(),
          ));
        } else {
          this.localSelectedItem.AvailableTillUTC = this.alreadySelectedDate;
        }
      }
      // api hit for creating group selection
      this.$axios
        .post('/GroupSelections/SaveOrUpdateGroupSelection', {
          groupSelectionsDto:this.localSelectedItem,
        })
        .then((response) => {
          // checking success or not
          if (response.data.success === false) {
            // alert message
            alert(response.data.message);
          } else {
            this.$emit('group-selection-cancel-operation');
            this.SendNotificationRequestToQueue(
              signalObject,
              response.data as GroupSelectionDetails,
            );
          }

        })
        .catch(() => {
          // Log error if required
        });
      this.Cancel();
      this.$v.$reset();
    }
  }
  // Set modal popup data for deleting group selection question
  private SetModelPopUpMessage() {
    this.unsavedModalPopUp = new VueModalPopUpViewModel(
      this.$t('Participants.GroupSelection.DeleteGroupSelection').toString(),
      this.$t('Participants.GroupSelection.DeleteGroupSelectionDescription').toString(),
      true,
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('@/assets/Images/Icon-warning.svg'),
      this.$t('CancelButton').toString(),
      this.$t('DeleteButton').toString(),
    );
  }
  // Cancel delete operation and close modal popup
  private CloseModelPopup() {
    this.showVueModalPopUp = false;
  }
  // Perform delete operation
  private DeleteModelPopUp() {
    this.showVueModalPopUp = false;
    const signalObject = this.SendNotificationRequest(true);
    // Delete API
    this.$axios
      .post(
        '/GroupSelections/DeleteGroupSelection',
        {
          gsId: this.localSelectedItem.Id,
        },
      )
      .then((response) => {
        if (response.data.success === false) {
          // Server side validation failuere
          alert(this.$t('Message.UnableToDelete'));
        } else {
          this.SendNotificationRequestToQueue(
            signalObject,
            this.localSelectedItem,
          );
          // Successful update / save
          this.$emit('group-selection-delete-operation');
        }
      })
      .catch(() => {
        // this.showLoading = false;
      });

  }
  // Close validation strip
  private CloseValidationSummary(val: boolean) {
    this.isValidationSummaryVisible = val;
  }
  // Show create new group popup
  private DisplayCreateNewGroup(val: boolean) {
    this.showCreateNewGroup = val;
  }
  // Assign newely created group to particular response option
  private AssignNewCreatedGroup(group: ParticipantGroups) {
    this.localSelectedItem.GroupSelectionOptions[this.createNewGroupIndex].AssignedGroup = group;
  }
  @Watch('SelectedItem')
  private CheckChangeInSelectedItem(val: GroupSelectionDetails) {
    if(!Common.prototype.CompareObjects(this.localSelectedItem, val) && this.isValidationSummaryVisible) {
      this.isValidationSummaryVisible = false;
    }
    this.localSelectedItem = JSON.parse(JSON.stringify(val));
    if(this.localSelectedItem.HasDeadline) {
      this.localSelectedItem.AvailableTillUTC = this.localSelectedItem.AvailableTillUTC === null ? moment(new Date()).tz(this.timeZone).toDate() :  moment(this.localSelectedItem.AvailableTillUTC).tz(this.timeZone).toDate();
      if(this.localSelectedItem.AvailableTillUTC !== null) {
        this.alreadySelectedDate = this.localSelectedItem.AvailableTillUTC;
      }
      this.minDateTime = new Date(this.ConvertDateToUtc(this.GetDateString())).toISOString();
    }
  }
}
