/* eslint-disable max-len */
import { BaseSignalRModel } from '../BaseSignalRModel';
import ModuleEnum from '@/enums/ModuleEnum';
import SubModuleEnum from '@/enums/SubModuleEnum';
import { SignalRModelRequest } from '../SignalRModelRequest';
import { Vue } from 'vue-property-decorator';
import { AxiosInstance } from 'axios';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import NotificationStatusEnum from '@/enums/NotificationStatusEnum';
import EmitEnum from '@/enums/EmitEnums';
import GroupSelectionDetails, { GroupSelectionDetailsSignalR } from '@/entities/Participants/GroupSelectionDetails';

const ParticipantsGroupSelectionSignalRModelRequestIds = {
  SingleCreate: 1,
  SingleEdit: 2,
  SingleDelete: 3,
  Sorting: 4,
};

export class ParticipantsGroupSelectionSignalRModel extends BaseSignalRModel {

  // Get SignalRModelRequest for adding a single group selection question
  get SingleCreateGroupSelectionQuestionSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ParticipantsGroupSelectionSignalRModelRequestIds.SingleCreate);
  }
  // Get SignalRModelRequest for Updating a single group selection question
  get SingleUpdateGroupSelectionQuestionSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ParticipantsGroupSelectionSignalRModelRequestIds.SingleEdit);
  }
  // Get SignalRModelRequest for Deleting a single group selection question
  get SingleDeleteGroupSelectionQuestionSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ParticipantsGroupSelectionSignalRModelRequestIds.SingleDelete);
  }
  get SortingGroupSelectionQuestionsSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ParticipantsGroupSelectionSignalRModelRequestIds.Sorting);
  }

  readonly GroupSelectionQuestionsBatchOperationURL = '/BulkOperations/PerformBatchOperation';
  readonly GroupSelectionQuestionsPostToClientsSignalRURL = '/BulkOperations/PostToClientsSignalR';

  constructor(public rootObj: any, public axios: AxiosInstance, public $store: any, public $t: any) {
    super(rootObj, axios, $store);
    const reqInfo: AppRequestInfo = this.GetRequestInfoFromStore();
    const loggedInUserFullName = reqInfo.FirstName + ' ' + reqInfo.LastName;
    const signalRAPIParticipantsURL = Vue.prototype.$SignalRBaseURL + 'api/SignalRParticipantsGroupSelections/PushParticipantsGroupSelectionsSignalRChanges';

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ParticipantsGroupSelectionSignalRModelRequestIds.SingleCreate, ModuleEnum.ParticipantsGroupSelections, SubModuleEnum.SingleCreate,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ParticipantsGroupSelectionSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIParticipantsURL, [], 'Notifications.SaveOperation', '', 'Notifications.SavingGroupSelectionQuestionItem', 'Notifications.SavedGroupSelectionQuestionItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleGroupSelectionQuestion'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ParticipantsGroupSelectionSignalRModelRequestIds.SingleEdit, ModuleEnum.ParticipantsGroupSelections, SubModuleEnum.SingleEdit, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ParticipantsGroupSelectionSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIParticipantsURL, [], 'Notifications.UpdateOperation', '', 'Notifications.UpdatingGroupSelectionQuestionItem', 'Notifications.UpdatedGroupSelectionQuestionItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleGroupSelectionQuestion'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ParticipantsGroupSelectionSignalRModelRequestIds.SingleDelete, ModuleEnum.ParticipantsGroupSelections, SubModuleEnum.SingleDelete,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ParticipantsGroupSelectionSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIParticipantsURL, [], 'Notifications.DeleteOperation', '', 'Notifications.DeleteSingleGroupSelectionItem', 'Notifications.DeletedSingleGroupSelectionItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionGroupSelectionQuestionDeleted'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ParticipantsGroupSelectionSignalRModelRequestIds.Sorting, ModuleEnum.ParticipantsGroupSelections, SubModuleEnum.Sorting, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ParticipantsGroupSelectionSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIParticipantsURL, [], 'Notifications.UpdateOperation', '', 'Notifications.SortingGroupSelectionQuestionsList', 'Notifications.SortedGroupSelectionQuestionsList', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleGroupSelectionQuestion'));
  }
  // Perform group selection Questions Single Operations
  public PerformGroupSelectionSingleOperation(signalRModelRequest: SignalRModelRequest) {
    // disable group selection Questions
    if (signalRModelRequest.SelectedIds.length > 0) {
      if (signalRModelRequest.SubModule !== SubModuleEnum.SingleCreate && signalRModelRequest.SubModule !== SubModuleEnum.Sorting) {
        this.DisableGroupSelectionQuestions(signalRModelRequest);
      }
    }
    this.SendSignalRModelRequestToHubWithoutAddingToQueue(signalRModelRequest, this.GroupSelectionQuestionsPostToClientsSignalRURL);
    this.SendSignalRModelRequestToQueueWithoutEmit(signalRModelRequest, this.GroupSelectionQuestionsBatchOperationURL);
  }

  // Method that fires when GroupSelectionSignalR response model is received from Hub
  public GroupSelectionSignalRModelResponseReceived(signalRModelRequest: SignalRModelRequest) {
    this.SignalRModelResponseReceived(signalRModelRequest);
    // enable group selection Questions

    if (signalRModelRequest.SubModule !== SubModuleEnum.SingleCreate && signalRModelRequest.SubModule !== SubModuleEnum.SingleDelete) {
      this.EnableGroupSelectionQuestions(signalRModelRequest);
    }
  }

  // Disable group selection Questions and add to Notification Popup that an operation has started
  public DisableGroupSelectionQuestionsAndAddToNotificationPopup(signalRModelRequest: SignalRModelRequest) {
    if (signalRModelRequest.SubModule !== SubModuleEnum.SingleCreate) {
      this.DisableGroupSelectionQuestions(signalRModelRequest);
    }
    this.EmitAddNewNotifications(signalRModelRequest);
  }

  // Get group selection Question Item to send to SignalR as JSON data
  public GetGroupSelectionQuestionItemToSendToSignalR(groupSelectionQuestion: GroupSelectionDetails): string {

    const item: GroupSelectionDetailsSignalR = GroupSelectionDetailsSignalR.GetGroupSelectionQuestionItemSignalR(groupSelectionQuestion);
    return JSON.stringify(item);
  }

  // Update group selection Question in array based on Index for data received from Signal R
  public FillGroupSelectionQuestionObjectFromSignalRResponse(
    groupSelectionQuestion: GroupSelectionDetailsSignalR,
    objIndex: number,
    groupSelectionQuestionsList: GroupSelectionDetails[],
  ): GroupSelectionDetails {
    const question = this.GetGroupSelectionQuestion(groupSelectionQuestionsList[objIndex], groupSelectionQuestion);
    return question;
  }


  // Create new group selection Question Item based on Response received from SignalR
  public FillGroupSelectionQuestionObjectFromSignalRResponseNewItem(
    groupSelectionQuestion: GroupSelectionDetailsSignalR,
  ): GroupSelectionDetails {
    const question = this.GetGroupSelectionQuestion(GroupSelectionDetails.createEmpty(), groupSelectionQuestion);
    return question;
  }

  // Disable Selected group selection Questions based on groupSelectionQuestion Ids
  private DisableGroupSelectionQuestions(signalRModelRequest: SignalRModelRequest) {
    this.rootObj.$emit(EmitEnum.disableGroupSelectionItems, signalRModelRequest.SelectedIds);
  }

  // Enable Selected group selection Questions based on groupSelectionQuestion Ids
  private EnableGroupSelectionQuestions(signalRModelRequest: SignalRModelRequest) {
    this.rootObj.$emit(EmitEnum.enableGroupSelectionItems, signalRModelRequest.SelectedIds);
  }

  // Get group selection Item from GroupSelectionSignalR Item

  private GetGroupSelectionQuestion(groupSelectionItem: GroupSelectionDetails, groupSelectionQuestion: GroupSelectionDetailsSignalR): GroupSelectionDetails {
    groupSelectionItem = GroupSelectionDetails.GetGroupSelectionQuestionItemFromGroupSelectionSignalR(groupSelectionItem, groupSelectionQuestion);
    return groupSelectionItem;
  }
}
