











































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ParticipantGroups } from '@/entities/ParticipantGroups';
@Component({})
export default class CreateGroup extends Vue {
  @Prop() Groups!: ParticipantGroups[];
  private maxLength = 100;
  private isMobileView = false;
  private localGroup: ParticipantGroups = ParticipantGroups.createEmpty();
  private anyError = false;
  private errorMessage = '';
  private isCreateNewGroupBtnDisabled = false;
  private mounted() {
    (this.$refs.groupName as HTMLElement).focus();
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }
  private CancelGroupCreation() {
    this.$emit('display-group-creation', false);
  }
  // Function to create a new group
  private CreateGroup() {
    let doesGroupExists = false;
    // Checking whether group name is empty
    if(this.localGroup.Name === '') {
      this.errorMessage = this.$t('Participants.Groups.NameFieldEmpty').toString();
    } else {
      // Checking whether the group name already exists
      this.Groups.forEach((item)=> {
        if(item.Name === this.localGroup.Name) {
          doesGroupExists = true;
          this.errorMessage = this.$t('Participants.Groups.GroupAlreadyExists').toString();
          return;
        }
      });
    }
    if(doesGroupExists || this.localGroup.Name === '') {
      this.anyError = true;
    } else {
      this.isCreateNewGroupBtnDisabled = true;
      // api hit for creating group and response is Id and Name
      this.$axios
        .post('/Participant/EditParticipantGroup', {
          participantGroupdto:this.localGroup,
        })
        .then((response) => {
          // checking success or not
          if(response.status === 200) {
            // if success is true
            // assigning Id and Name
            this.localGroup.Id = response.data.Id;
            this.localGroup.Name = response.data.Name;
            // any error is false
            this.anyError = false;
            // closing modal popup
            this.$emit('display-group-creation', false);
            // emitting new created group
            this.$emit('new-created-group', this.localGroup);
            this.$root.$emit('new-created-group', this.localGroup);
            this.isCreateNewGroupBtnDisabled = false;
          }  else {
            // checking success or not if success is false show error
            if (response.data.Data.Success === false) {
              doesGroupExists = true;
              this.errorMessage = this.$t('Participants.Groups.GroupAlreadyExists').toString();
              this.anyError = true;
              this.isCreateNewGroupBtnDisabled = false;
              return;
            }
          }
        })
        .catch(() => {
          // Log error if required
        });
    }
  }
}
